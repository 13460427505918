import React from 'react';
import { Auth } from 'aws-amplify';
import { eraseCookie } from '../../components/LandingPage/helpers/cookieHelper';

class ForceLogout extends React.Component {
	componentDidMount() {

        eraseCookie("id_token");
        eraseCookie("access_token");
        eraseCookie("refresh_token");

        localStorage.removeItem('client-id');
        Auth.signOut()
	}

	render() { return null; }
}

export default ForceLogout;
